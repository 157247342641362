<template>
    <div class="base-box">
        <el-form ref="baseInfoform" :model="baseInfo" :rules="rules" label-width="140px">
            <el-form-item label="商户类型" prop="">
                <el-select v-model="baseInfo.merType" placeholder="请选择">
                    <el-option label="个人商户" value="1" ></el-option>
                    <el-option label="个体工商户" value="3" ></el-option>
                    <el-option label="企业商户" value="2" ></el-option>
                </el-select>
            </el-form-item>
            <template v-if="baseInfo.merType=='2' || baseInfo.merType=='3'">
                <div class="images-box">
                    <el-form-item prop="licenseImg">
                        <div class="images-item">
                            <div class="images-uploader">
                                <OssUpload @change="getFaceImg" :oldImg="baseInfo.licenseImg" ocrType="BUSINESS_LICENSE" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true"></OssUpload>
                            </div>
                            <div class="images-title"><span>*</span>营业执照照片</div>
                        </div>
                    </el-form-item>
                </div>
                <el-form-item label="营业执照名称" prop="companyName">
                    <el-col :span="12"><el-input v-model="baseInfo.companyName" placeholder="请输入营业执照名称"></el-input></el-col>
                </el-form-item>
                <el-form-item label="营业执照编号" prop="busiLicenseCode">
                    <el-col :span="12" style="margin-right:10px"><el-input v-model="baseInfo.busiLicenseCode" placeholder="请输入营业执照编号"></el-input></el-col>
                </el-form-item>
                <el-form-item label="法人姓名" prop="legalName">
                    <el-col :span="12"><el-input v-model="baseInfo.legalName" placeholder="请输入法人姓名"></el-input></el-col>
                </el-form-item>
                <el-form-item label="营业执照有效期" prop="busiLicensePeriod">
                    <el-col :span="9"><el-date-picker placeholder="选择日期" v-model="baseInfo.busiLicensePeriod" value-format="yyyy-MM-dd" :disabled="foreverCheck" type="date" style="width: 100%;"></el-date-picker></el-col>
                    <el-col :span="2" style="padding-left:10px"><el-checkbox v-model="foreverCheck" @change="baseInfo.busiLicensePeriod=0">永久有效</el-checkbox></el-col>
                </el-form-item>
            </template>

            <el-form-item label="商户简称" prop="merSubName">
                <el-col :span="12"><el-input v-model="baseInfo.merSubName" placeholder="请输入商户简称"></el-input></el-col>
            </el-form-item>
            <el-form-item label="商户地址" prop="provinceCode">
                <div style="float:left">
                    <el-form-item prop="provinceCode">
                    <el-select v-model="provinceName" placeholder="请选择" @change="provinceChange">
                        <el-option v-for="item in provinceCityArea" :key="item.code" :label="item.name" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                </div>
                <div style="float:left; padding:0 10px" :span="1"> - </div>
                <div style="float:left">
                    <el-form-item prop="cityCode">
                        <el-select v-model="cityName" placeholder="请选择" @change="cityChange">
                            <el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item" ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div style="float:left; padding:0 10px" :span="1"> - </div>
                <div style="float:left">
                    <el-form-item prop="areaCode">
                        <el-select v-model="baseInfo.areaCode" placeholder="请选择">
                            <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form-item>
            <el-form-item label="" prop="address">
                <el-col :span="12"><el-input type="text-area" v-model="baseInfo.address" placeholder="请填写详细地址"></el-input></el-col>
            </el-form-item>
            <el-form-item label="商户行业">
                <div style="float:left">
                    <el-form-item prop="majorMcc">
                    <el-select v-model="baseInfo.majorMcc" placeholder="请选择" @change="getSubMcc">
                        <el-option v-for="item in mccList" :key="item.id" :label="item.mccTxt" :value="item.mccCode" ></el-option>
                    </el-select>
                </el-form-item>
                </div>
                <div style="float:left; padding:0 10px" :span="1"> - </div>
                <div style="float:left">
                    <el-form-item prop="subMcc">
                        <el-select v-model="baseInfo.subMcc" placeholder="请选择">
                            <el-option v-for="item in subMccList" :key="item.id" :label="item.mccTxt" :value="item.mccCode" ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="contactName">
                <el-col :span="12"><el-input v-model="baseInfo.contactName" placeholder="请输入联系人姓名"></el-input></el-col>
            </el-form-item>
            <el-form-item label="联系人手机号" prop="mobileNo">
                <el-col :span="12"><el-input v-model="baseInfo.mobileNo" maxlength="11" placeholder="请输入联系人电话"></el-input></el-col>
            </el-form-item>
            <el-form-item label="驳回原因" v-if="checkMsg">
                <el-input type="textarea" v-model="checkMsg"></el-input>
            </el-form-item>
        </el-form>
        <el-row :gutter="20">
            <el-col :span="12" :offset="6">
                <!-- <el-button>返回</el-button> -->
                <el-button type="primary" v-if="active!=4" @click="nextClick('baseInfoform')">下一步</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { AgentApi,CommonApi } from '@/api'
import {formatOCRDate } from '@/utils/tools'
export default {
    data() {
        var checkDate = (rule, value, callback) => {
            if (!value&&!this.foreverCheck) {
            return callback(new Error('有效期不能为空'));
            } else{
                callback();
                
            }
        };
        var checkLicenseImg = (rule, value, callback) => {
            if (!this.baseInfo.licenseImg) {
            return callback(new Error('请上传营业执照'));
            } else{
                value = this.baseInfo.licenseImg
                callback();
            }
        };
        var checkProvinceCode = (rule, value, callback) => {
            if (!this.baseInfo.provinceCode) {
            return callback(new Error('请选择省份'));
            } else{
                value = this.baseInfo.provinceCode
                callback();
            }
        };
        var checkCityCode = (rule, value, callback) => {
            if (!this.baseInfo.cityCode) {
            return callback(new Error('请选择城市'));
            } else{
                value = this.baseInfo.cityCode
                callback();
            }
        };
        return {
        baseInfo: {merType:'1',parentAgentNo:'',cityCode:'',areaCode:'',subMcc:''},
        options:[],
        pageNo: 1,
        pageSize: 10,
        cityList:[],
        operaterUserList: [],
        hasParent: false,
        foreverCheck: false,
        cityName:'',
        mccList: [],
        subMccList: [],
        rules: {
          licenseImg: [{ validator : checkLicenseImg, trigger: 'change' }],
          companyName: [{ required: true, message: '请输入营业执照名称', trigger: 'blus' }],
          busiLicenseCode: [{ required: true, message: '请输入营业执照编号', trigger: 'blur' }],
          provinceCode: [{ validator : checkProvinceCode, trigger: 'change' }],
          cityCode: [{ validator : checkCityCode, trigger: 'change' } ],
          areaCode: [{ required: true, message: '请选择地区', trigger: 'change' } ],
          majorMcc: [{ required: true, message: '请选择大类', trigger: 'change' } ],
          subMcc: [{ required: true, message: '请选择细类', trigger: 'change' } ],
          address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
          merSubName: [{ required: true, message: '请输入商户简称', trigger: 'blur' }],
          legalName: [ { required: true, message: '请输入法人代表姓名', trigger: 'blur' } ],
          legalIdCardNo: [ { required: true, message: '请输入法人代表身份证号码', trigger: 'blur' },{pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号码'}],
          busiLicensePeriod: [ { required: true, message: '请选择营业执照有效期', trigger: 'change' } ],
          contactName: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
          mobileNo: [ {  required: true, message: '请输入联系人手机号', trigger: 'blur' }],
        },
        
       }
    },
    created() {
        CommonApi.listMcc({level:'1'})
            .then(res=>{
                if(res.success){
                    this.mccList = res.data
                }
            })
    },
    props:{
        provinceCityArea:{
            type: Array,
            default: []
        },
        type:{
            type: String,
            default: 'org'
        },
        checkMsg:{
            type: String,
            default: ''
        },
        isUpdate:{
            type: Boolean,
            default: false
        },
        baseData:{
            type: Object,
            default:{}
        }
    },
    watch:{
        baseData:{
            handler(val){
                if(val&&this.isUpdate){
                    this.baseInfo = val
                    if(this.baseInfo.busiLicensePeriod=='0'){
                        this.foreverCheck = true
                    }
                    for(let i=0;i<this.provinceCityArea.length;i++){
                        if(val.provinceCode == this.provinceCityArea[i].code){
                            this.provinceName = this.provinceCityArea[i].name
                            this.cityList = this.provinceCityArea[i].children
                        }
                    }
                    for(let i =0;i< this.cityList.length;i++){
                        if(val.cityCode == this.cityList[i].code){
                            this.cityName = this.cityList[i].name
                            this.areaList = this.cityList[i].children
                            return
                        }
                    }
                    this.getSubMcc()
                }
                
            },
            immediate: true
        }
        
    },
    methods: {
        getFaceImg(fileList,filename,ocrData){
            if(JSON.stringify(ocrData) != '{}'){
                this.$set(this.baseInfo,'companyName',ocrData['单位名称'].words)
                this.$set(this.baseInfo,'busiLicenseCode',ocrData['社会信用代码'].words)
                if(ocrData['法人'].words!='无'){
                    this.$set(this.baseInfo,'legalName',ocrData['法人'].words)
                }
                if(ocrData['有效期'].words=='无'){
                    this.foreverCheck = true
                    this.baseInfo.busiLicensePeriod='0'
                } else{
                    this.foreverCheck = false
                    this.$set(this.baseInfo,'busiLicensePeriod',formatOCRDate(ocrData['有效期'].words))
                }
            }
            this.baseInfo.licenseImg = filename
        },
        getNationalEmblemImg(fileList,filename){
            var file = filename.slice(-1)
            this.baseInfo.idCardNationalEmblemImg = filename
        },
        async remoteMethod(query){
            if(query!=''){
               let res = await AgentApi.listUpperOrg(this.pageNo,this.pageSize,{searchValue:query})
               if(res.success){
                   this.options = res.data
               }
            } else{
                this.options = []
            }
        },
        provinceChange(val){
            this.baseInfo.cityCode = ''
            this.cityName = ''
            this.provinceName = val.name
            this.baseInfo.provinceCode = val.code
            this.cityList = val.children
        },
        cityChange(val){
            this.baseInfo.areaCode = ''
            this.cityName = val.name
            this.baseInfo.cityCode = val.code
            this.areaList = val.children
        },
        nextClick(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('change',this.baseInfo,'next')
                } else {
                    return false;
                }
            });
        },
        getSubMcc(){
            this.baseInfo.subMcc = ''
            CommonApi.listMcc({fatherCode: this.baseInfo.majorMcc})
                .then(res=>{
                    if(res.success){
                        this.subMccList = res.data
                    } else{
                        this.subMccList = []
                    }
                })
        }
    },
}
</script>